.header {
    background: #182933;
    height: 80px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
}

.header-last {
    background-color: #182933;
    height: auto;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

    .muiDescripationFooterInfo {
        font-family: 'Montserrat';
        font-size: 14px;
        color: #FFFFFF;
    }

    .muiDescripationFooterInfoContact {
        font-family: 'Montserrat';
        font-size: 14px;
        color: #BFBFBF;
        font-weight: 300;
    }

    .muiDescriptionFooterHeading {
        // font-family: 'Montserrat';
        font-family: sans-serif;
        font-size: 14px;
        font-weight: 900;
        color: #FFFFFF;
    }

    .muiDescripationFooterSubHeading {
        font-family: sans-serif;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
    }
}

.header-partner {
    .text {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
    }

    .btn-login {
        width: 95px;
        height: 40px;
        border-radius: 4px !important;
        padding: 10px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        border: 2px solid #FFFFFF !important;
        color: #FFFFFF !important;
        background: transparent !important;
        text-transform: capitalize !important;
        letter-spacing: 1px;
        line-height: 0px !important;
        text-align: center;

        &:hover {
            background: #FFFFFF !important;
            color: #182933 !important;
        }

        &:focus {
            background: #FFFFFF !important;
            color: #182933 !important;
        }
    }

    .btn-white {
        background: #FFFFFF !important;
        color: #182933 !important;

        &:hover {
            color: #FFFFFF !important;
            background: transparent !important;
        }

        &:focus {
            color: #FFFFFF !important;
            background: transparent !important;
        }
    }
}

.text-step {
    color: #182933;
    font-size: 32px;
    font-weight: bold;
    font-family: 'Montserrat';
}

.step-info {
    .text {
        color: #182933;
        font-size: 24px;
        font-weight: 700;
    }

    .desc {
        color: #808080;
        font-size: 20px;
        font-weight: 500;
    }

    .main-round {
        background-color: #143979;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-heading {
        // font-family: 'Montserrat';
        font-family: sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #343541;
        display: flex;
        align-items: center;
    }

    .main-description {
        font-family: sans-serif;
        font-size: 14px;
        color: #595959;
        margin-left: 40px;
    }
}


.banner-image-home {
    background-image: url('../../assets/images/landing/home-shopeez.png');
    width: 100%;
    height: 950px;
    // display: flex;
    // align-items: center;
    padding-left: 88px;

    .shopez-logo {
        margin-bottom: 70px !important;
    }

    .img-title {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 1.2;
        letter-spacing: 0.02em;
        margin-top: 70px !important;
        color: #3D3C3C;
    }

    .muiDescripationHomeInfo {
        // font-family: sans-serif;
        font-family: 'Montserrat';
        margin-top: 20px;
        font-size: 16px;
        color: #6D6E71;
        margin-bottom: 40px !important;
    }

    .muiDescripationHomeStoreInfo {
        font-family: sans-serif;
        font-size: 20px;
        font-weight: bold;
        // color: #FFFFFF;
    }

    .get-button {
        width: 160px;
        height: 40px;
        background-color: #EBB52A;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 16px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px !important;
        cursor: pointer;
    }
}



.img-des {
    margin-left: 20px !important;
    // margin-right: 20px !important;
}

@media (max-width: 1436px) {
    .muiDescripationHomeInfo {
        font-size: 30px !important;
    }
}

@media (min-width: 1186px) and (max-width: 1296px) {
    .header-last {
        height: auto !important;
    }

    .muiDescripationFooterInfo {
        font-size: 17px !important;
    }

    .muiDescripationFooterInfoContact {
        font-size: 15px !important;
    }

    .muiDescriptionFooterHeading {
        font-size: 18px !important;
    }

    .muiDescripationFooterSubHeading {
        font-size: 15px !important;
    }

}

@media (min-width: 767px) and (max-width: 1185px) {
    .header-last {
        height: auto !important;
    }

    .muiDescripationFooterInfo {
        font-size: 16px !important;
    }

    .muiDescripationFooterInfoContact {
        font-size: 14px !important;
    }

    .muiDescriptionFooterHeading {
        font-size: 14px !important;
    }

    .muiDescripationFooterSubHeading {
        font-size: 13px !important;
    }
}

@media (max-width: 1199px) {
    .img-des {
        margin: 0 !important;
    }
}

@media (max-width: 991px) {
    .img-des {
        margin: 0 !important;
    }
}

@media (max-width:767px) {
    .header-last {
        height: auto !important;

        .muiDescriptionFooterHeading {
            padding-top: 20px;
        }
    }
}