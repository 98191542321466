.welcome_full_page{
    height: 100%;
    width: 100%;
}
.welcom_thanks .back_home{
    width: 26px;//36px;
    height: 36px; 
}
.back_set_welcome{
    top: 115px;
    left: 58px;
    position: fixed;
}
.ThanksPasswordDeal{
    background-size: cover !important;
}