.productInfoSection{
     margin-top: 0px;
     width: 100%;
     border-collapse: separate;
     border-spacing: 0vw 1vw;
     cursor: pointer;
}
.pDetail {
     font-family: "Segoe UI", Arial, sans-serif;
     font-style: normal;
     font-weight: 500;
     font-size: 12px !important;
     color: #606060;
     margin-right: 2px;
}

@media screen and (max-width: 1550px) {
     .pDetail { 
          font-size: 10px !important;
     }
}

@media screen and (max-width: 1000px) {
     .setting_product_name .col-1{
          padding-left: 3px;
          padding-right: 3px;
          word-break: break-word;
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
     }
     .setting_product_name .productItem{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
     }
}
