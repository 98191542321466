.onboardingNavbar {
    background: #1D3762;//  #203864;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    justify-content: space-between;
}
.percentage {
    color: #FFFFFF;
    height: 1.3vw;
    font-family: "Roboto-Regular";
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6vw;
    letter-spacing: 0em;
    padding-top: 1.5vw;
    width: 8vw;
}

.storeName {
    font-family: "Roboto-Regular";
    font-size: 1vw;
    font-weight: 500;
    line-height: 2.3vw;
    letter-spacing: 0em;
    margin-top: 1px;
}

.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    padding-left: 10vw
}

.shopeezlogo{
    width: 87px;
    height: 24px;
}

.menuicon{
    width: 18px;
    height: 18px; 
}

.logout {
    background-color: transparent;
    color: white;
    border-color: transparent;
    margin-top: -0.0vw;
    margin-right: 2vw;
    height: 1.5vw;
    font-family: "Inter", Arial, sans-serif !important;;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 

}

.navBar-menu {
    float: left;
    margin-left: 65vw;
}

.logo-center {
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

.deplist {
    max-height: 100px;
    overflow-y: auto;
    padding-left: 0px;
}

.depli {
    float: unset;
    list-style-type: none;
    padding-left: 20px;
    width: 100%;
    overflow-x: hidden;
    padding-right: 20px;

}

.dropdown-menu {

    position: absolute;
    right: 15px !important;
    margin: 0;
    left: 63rem;
    display: block;
    background: #ECECEC;
}

.hide-dropdown {
    display: none;
}

.stickNav {

    position: sticky;
    z-index: 1000;
    top: 0px;
}
// .wrapper {
//     border: 3px solid blue;
//   }
  
//   .container {
//     background-color: pink;
//   }
  
//   .barCompleted {
//     background-color: lightblue;
//     width: 80%;
//   }
  
//   .label {
//     font-size: 20px;
//     color: green;
//   }
.navProcess{
    width: 100%;
}