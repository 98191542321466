.setPasswordDeal {
    height: 100vh;
    background-color: #E8E6FE;
    text-align: center;
}

.set_card_container {
    // height: 50rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    padding-top: 50px;
    margin-top: 75px;
    margin-left: 140px;
    margin-right: 140px;
    margin-bottom: 75px;
    position: absolute;
    border: 1px solid #CFCFCF;
}

.set_card_container-error {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    padding-top: 160px;
    position: absolute;
}

.heading_error {
    font-size: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.error_text {
    font-size: 30px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.text_mng {
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 32px;
}

.text_mng.submit_div {
    margin-top: 51px;
    margin-bottom: 52px;
}

.text_mng.first_div_top {
    margin-top: 45px;
}

.logo_home {
    top: 50px;
    max-width: 200px;
    position: absolute;
    left: 60px;
}

.logo_set {
    position: relative;
    top: 14%;
    max-width: 100%;
    margin-top: -110px;
}

// .forget_left{
//     background: #E8E6FE;
// }
.mobile-setting {
    display: none;
}

.new_img_logo {
    width: 173px;
    height: 173px;
}

.img_div {
    padding-bottom: 20px;
}