@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body,
html,
.App,
#root,
.auth-wrapper {
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    background: #fff !important;
    height: max-content;
    display: flex;
    font-weight: 400;
    font-family: "Inter", Arial, sans-serif !important;
    overflow-x: hidden;
    font-size: 14px !important;
}

button,
input,
optgroup,
select,
textarea {
    font-size: 14px !important;
    font-family: "Inter", Arial, sans-serif !important;
}

.f-600 {
    font-weight: 600 !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.MuiFilledInput-underline:before {
    border-bottom: 0px !important
}

.MuiFilledInput-underline::after {
    border-bottom: 0px !important
}

.MuiFormLabel-root.Mui-focused {
    color: #233949 !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.8vw !important;
    margin: 0;
    display: block;
    padding: 5px 0px !important;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 40px white inset !important;
    }
}


.MuiRadio-colorSecondary.Mui-checked {
    color: black !important;
}

.PrivateTabIndicator-colorPrimary-282 {
    background-color: black !important;
}

.dispFlex {
    display: "flex";
}

.screenLeftMargin {
    margin-left: 150px !important;
}

.screenLeftMarginResponse {
    margin-left: 220px !important;
}

.screenLeftMarginEnquiry {
    margin-left: 220px;
}

.MuiFormControl-root {
    height: 45px;
    width: 100%;
    background-color: #FFFFFF;
    border: none;
    border-radius: 4px;

    .MuiFormLabel-root {
        left: 0px;
        top: -4px;
        font-family: "Inter", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #808080;
        font-size: 14px !important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        top: 0px;
    }

    .MuiInputBase-root {
        border-radius: 4px;
        height: 45px;
        padding: 15px;
        color: #000;
        font-family: "Inter", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        background-color: #FFFFFF;

        .MuiOutlinedInput-notchedOutline {
            border-color: #C0C0C0 !important;
            border-width: 1px !important;
        }

        .MuiInputAdornment-root {
            margin-top: 0px !important;
            margin-right: 0px;
            // margin-left: 5px;
        }

        .MuiSelect-root {
            padding: 10px 0px !important;
            padding-top: 12px !important;
            padding-bottom: 0px !important;
            font-size: 14px !important;


            &:focus {
                background-color: #FFFFFF;
            }
        }

        .MuiSelect-nativeInput {
            height: 45px;
        }
    }
}

.geosuggest {
    .geosuggest__input-wrapper {
        .geosuggest__input {
            width: 100%;
            height: 45px;
            border-radius: 4px !important;
            padding: 15px;
            background: #FFFFFF !important;
            border: 1px solid #C0C0C0 !important;

            &:focus {
                box-shadow: 0 0 0 transparent;
            }
        }
    }

    .geosuggest__suggests {
        position: absolute;
        top: 100%;
        left: 15px;
        right: 15px;
        max-height: 25em;
        padding: 0;
        margin-top: 0px;
        background: #ECECEC;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top-width: 0;
        overflow-x: hidden;
        overflow-y: auto;
        list-style: none;
        z-index: 5;
        -webkit-transition: max-height 0.2s, border 0.2s;
        transition: max-height 0.2s, border 0.2s;

        .geosuggest__item {
            font-size: 14px !important;
            font-family: "Inter", Arial, sans-serif;
            padding: .5em .65em;
            cursor: pointer;

            &:hover {
                background: #f5f5f5;
            }

            &:focus {
                background: #f5f5f5;
            }
        }

        .geosuggest__item--active {
            background: #267dc0;
            background: #ECECEC;

            &:hover {
                background: #ccc;
            }

            &:focus {
                background: #ccc;
            }
        }

        .geosuggest__item__matched-text {
            font-weight: bold;
        }
    }

    .geosuggest__suggests--hidden {
        max-height: 0;
        overflow: hidden;
        border-width: 0;
    }
}

.jodit-react-container {
    .jodit-container {
        .jodit-workplace {
            background: #FFFFFF;

            .jodit-placeholder {
                color: #808080;
            }
        }
    }
}

.MuiList-root {
    .MuiListSubheader-root {
        .MuiFormControl-root {
            height: 45px;
            background: #fff;
            border-radius: 0;

            .MuiInputBase-root {
                .MuiInputAdornment-root {
                    margin-top: 0px !important;
                }
            }
        }
    }
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #000 !important;
}

.customreact-select {
    height: 45px;
    z-index: 2;

    .css-yk16xz-control {
        height: 45px;
        background: #FFFFFF;
        color: rgba(0, 0, 0, 0.54);
        border: 1px solid #CECECE;

        .css-g1d714-ValueContainer {
            height: 45px;
        }

        .css-1hb7zxy-IndicatorsContainer {
            height: 45px;
        }
    }

    .css-1pahdxg-control {
        height: 45px;
        background: #ECECEC;
        color: #808080;
        border: none;

        .css-g1d714-ValueContainer {
            height: 45px;
        }

        .css-1hb7zxy-IndicatorsContainer {
            height: 45px;
        }
    }
}

.MuiCheckbox-root {
    color: #000;
    padding: 0px 5px 0px 0px !important;
}

.MuiCheckbox-colorPrimary {
    .Mui-checked {
        &:hover {
            background-color: #808080;
        }
    }
}

.br-left-top-bottom {
    .MuiInputBase-root {
        border-radius: 4px 0px 0px 4px !important;

        .MuiOutlinedInput-notchedOutline {
            border-right: 0px !important;
        }
    }
}

.br-right-top-bottom {
    .MuiInputBase-root {
        border-radius: 0px 4px 4px 0px !important;

        .MuiOutlinedInput-notchedOutline {
            border-left: 0px !important;
        }
    }
}

.btn-primary {
    background-color: #203864 !important;
    border: 2px solid #203864 !important;
    box-shadow: none;
    width: 145px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #fff !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-primary-pickup {
    background-color: #203864 !important;
    border: 2px solid #203864 !important;
    box-shadow: none;
    width: 120px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #fff !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-primary-verify {
    background-color: #203864 !important;
    border: 2px solid #203864 !important;
    box-shadow: none;
    width: 415px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #fff !important;
    margin-top: 35px;
    margin-left: 25px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.order-btn {
    width: 250px !important;
}

.btn-secondary {
    background-color: #6c757d !important;
    border: 2px solid #6c757d !important;
    box-shadow: none;
    width: 145px;
    border-radius: 0 !important;
    padding: 9px !important;
    text-transform: capitalize;
    font-size: 13px !important;
    letter-spacing: 1px;
    color: #fff !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #6c757d !important;
        border: 2px solid #6c757d !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: #6c757d !important;
        border: 2px solid #6c757d !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:hover {
        background-color: #6c757d !important;
        border: 2px solid #6c757d !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-cancle {
    background-color: #fff !important;
    border: 2px solid #203864 !important;
    box-shadow: none;
    width: 145px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #000 !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: transparent !important;
        border: 2px solid #203864 !important;
        color: #000 !important;
        box-shadow: none !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-batch {
    background-color: #fff !important;
    border: 2px solid #203864 !important;
    box-shadow: none;
    width: 175px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #000 !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: transparent !important;
        border: 2px solid #203864 !important;
        color: #000 !important;
        box-shadow: none !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-available {
    background-color: #fff !important;
    border: 2px solid red !important;
    box-shadow: none;
    width: 145px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: red !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: transparent !important;
        border: 2px solid #203864 !important;
        color: #000 !important;
        box-shadow: none !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-cancle-pickup {
    background-color: #fff !important;
    border: 2px solid #203864 !important;
    box-shadow: none;
    width: 120px;
    border-radius: 0 !important;
    padding: 10px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #000 !important;
    margin: 0px;
    font-family: "Inter", Arial, sans-serif;

    &:active {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }

    &:focus {
        background-color: transparent !important;
        border: 2px solid #203864 !important;
        color: #000 !important;
        box-shadow: none !important;
    }

    &:hover {
        background-color: #203864 !important;
        border: 2px solid #203864 !important;
        color: #fff !important;
        box-shadow: "0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)" !important;
    }
}

.btn-lg {
    width: 180px !important;
}

.btn-sm {
    width: 120px !important;
    padding: 5px 10px !important;
}

.heading-title {
    color: #000;
    font-size: 24px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.dashboard-date {
    color: #007BFF;
    font-size: 18px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
}

.image-word {
    width: 90px;
    word-break: break-all;
    font-size: 12px !important;
}

.storeImageBlock {
    width: 90px;
    height: 90px;

    .imgTagBlock {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        border: 1px solid #000;
    }

    .uploadPhoto {
        width: 90px;
        height: 90px;
        background: #FFFFFF;
        border: 1px solid #303030;
        border-radius: 4px;

        .uploadInfo {
            text-align: center;
            margin-top: 24px;

            .uploadText {
                margin-top: 5px;
                font-family: "Inter", Arial, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 16px;
            }
        }
    }
}


.uploadBox {
    width: 100%;
    height: 100px;
    background: #ECECEC;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: pointer;

    .imageUpload {
        width: 100%;
        height: 70px;
        background-color: #ECECEC;
        padding: 10px;

        .imageUploadSection {
            border: 1px solid #233949;
            opacity: 0.75 !important;
            background-color: #ECECEC;
            text-align: center;
            padding-top: 12px;
            justify-content: center;
        }
    }
}

.uploadedDoc-100 {
    border: 1px solid #233949;
    background-color: #FFFFFF;
    width: 100px;
    height: 100px;
    margin-top: 15px
}

.otp-heading {
    color: #203763;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px;
    margin-bottom: 0px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.h2-label {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 22px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h3-label {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 20px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h4-label {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 18px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h4-label-heading {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 18px;
    margin-bottom: 0px;
}


.h5-label {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h5-label-pickup {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    margin-bottom: 0px;
    // text-transform: capitalize;
}

.h5-label-pickup-name {
    color: #404040;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px !important;
    margin-bottom: 0px;
}

.h5-label-annual {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h5-label-resend {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h5-label-status {
    color: #5A5A5A;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    // font-weight: 600;
    font-size: 16px !important;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.h5-lable-heading {
    color: #000000;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    margin-bottom: 0px;
}

/*-------------------------------------------------------------------*/
.verticall_thanks_center {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 202px;
    // padding-bottom: 100px;
}

.ThanksPasswordDeal {
    font-family: "Inter", Arial, sans-serif;
    min-height: 100vh;
}

.thanks_img_logo {
    width: 593px;
    height: 500px;
}

.ThanksPasswordDeal .heading-title-t {
    color: #1D3762;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
}

.ThanksPasswordDeal p {
    color: #505050;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.h6-label {
    margin-bottom: 0px;
    color: #707070;
    font-size: 14px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.h6-label-deliver {
    margin-bottom: 0px;
    color: #707070;
    font-size: 17px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.h6-label-deliver-check {
    margin-bottom: 0px;
    color: #000000;
    font-size: 14px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.h6-lable-about-store {
    margin-bottom: 0px;
    color: #7f7f7f;
    font-size: 16px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.h6-label-tagline {
    margin-bottom: 0px;
    // color: #707070;
    font-size: 15px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.h6-label-heading {
    margin-bottom: 0px;
    color: #5A5A5A;
    font-size: 14px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn.submit-btn-set.btn-primary {
    color: #FFF;
    font-size: 20px;
    font-family: "Inter", Arial, sans-serif !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

button.button_verify {
    width: 100%;
}

.first_div_top_otp {
    margin-top: 32px;
}

.opt_box {
    margin-left: auto;
    margin-right: auto;
}

.loginForm.welcom_thanks {
    background: transparent;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcom_thanks .logo_home {
    position: fixed;
}

.geosuggest__suggests-wrapper {
    position: absolute;
    z-index: 3;
}

.geosuggest .geosuggest__suggests {
    position: unset;
}

.MuiFormControl-root .MuiInputBase-root.country_changes {
    padding-top: 1px;
}

.back_set_other {
    width: 26px; //36px;
    height: 26px; //36px;
}

.back_other {
    margin-left: 30px;
}

.back_set_other {
    margin-top: 40px;
}

@media (min-width: 1400px) {

    .otp_max_width,
    .text_mng_lr.otp_max_width {
        max-width: 440px;
        margin-left: auto;
        margin-right: auto;
    }

    .order-btn {
        width: 200px;
    }
}

@media (max-width: 1100px) {
    .banner-image-home h2.img-title {
        font-size: 28px;
    }

    .banner-image-home div.muiDescripationHomeInfo {
        font-size: 14px;
    }

    .banner-image-home div.muiDescripationHomeStoreInfo {
        font-size: 20px;
    }

    .MuiFormControl-root .MuiInputBase-root.country_changes {
        padding-top: 8px;
    }
}

@media (max-width: 1400px) {
     .dashboard-date {
        font-size: 14px !important;
    }
    .banner-image-home h2.img-title {
        font-size: 28px;
    }

    .banner-image-home div.muiDescripationHomeInfo {
        font-size: 14px;
    }

    .banner-image-home div.muiDescripationHomeStoreInfo {
        font-size: 20px;
    }

    #create-card.set_card_container {
        height: auto;
        margin-top: 100px;
        margin-left: 80px;
        margin-right: 80px;
        margin-bottom: 100px;
    }

    #create-card.login_card_container,
    #create-card.forgot_card_container {
        height: auto;
        margin-left: 80px;
        margin-right: 80px;
    }

    .react-switch-handle {
        height: 18px !important;
        width: 16px !important;
        // transform: translateX(16px) !important;
        margin-top: 5px !important;
    }

    .selected_sett .react-switch-handle {
        transform: translateX(16px) !important;
    }

    .react-switch-bg {
        height: 20px !important;
        width: 33px !important;
        margin-top: 5px !important;
    }

    .MuiFormLabel-root.tax_exempted {
        margin-right: 4px !important;
    }
}

@media (max-width: 1000px) {
    .react-switch-handle {
        height: 16px !important;
        width: 16px !important;
        // transform: translateX(16px) !important;
    }

    .react-switch-bg {
        height: 19px !important;
        width: 33px !important;
    }
}

@media (max-width: 991px) {

    #create-card.forgot_card_container,
    #create-card.set_card_container {
        margin-bottom: 102px;
    }

    .back_set_other {
        margin-top: 57px;
    }

    .otp_max_width,
    .text_mng_lr.otp_max_width {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .thanks_img_logo {
        width: 350px;
    }

    .ThanksPasswordDeal .heading-title-t {
        font-size: 35px;
        line-height: 100%;
    }

    .ThanksPasswordDeal p {
        font-size: 22px;
        line-height: 100%;
    }

    // .verticall_thanks_center{
    // padding-top: 140px;
    // }
    .loginForm .Geosuggest_main_setting {
        width: calc(100% + 40px);
    }

    .Geosuggest_main_setting .Geosuggest_setting {
        width: calc(100% - 55px);
    }

    .Geosuggest_main_setting .inputLocationIcon {
        padding: 6px 12px;
        width: 29px;
    }

    .forget_left .forgotPasswordDeal,
    .forget_left .setPasswordDeal,
    .forget_left .LoginPasswordDeal {
        height: 100%;
        padding-top: 30px;
        background-image: unset !important;
        padding-right: inherit;
    }

    .logo_forgot.mobile-setting {
        display: inline;
        top: 0px;
        margin-top: 0px;
        // margin-bottom: 0px;
    }

    .forget_left .forgotPasswordDeal,
    .forget_left .setPasswordDeal,
    .forget_left .LoginPasswordDeal {
        min-height: auto;
    }

    img.logo_home {
        top: 30px;
        left: 30px;
        max-width: 150px;
    }

    .logo_forgot,
    .logo_set {
        position: relative;
        top: unset;
        margin-top: 0px;
        margin-bottom: 40px;
    }

    .forgot_card_container,
    .set_card_container,
    .login_card_container {
        height: auto;
        margin-top: 80px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 80px;
    }

    .banner-image-home h2.img-title {
        font-size: 28px;
    }

    .banner-image-home div.muiDescripationHomeInfo {
        font-size: 14px;
    }

    .banner-image-home div.muiDescripationHomeStoreInfo {
        font-size: 20px;
    }
}

// @media (max-width: 500px) {
//     img.logo_home {
//         max-width: 120px;
//     }

//     .ThanksPasswordDeal .logo_home {
//         max-width: 130px;
//         left: 40px;
//     }

//     .thanks_img_logo {
//         width: 250px;
//     }

//     .ThanksPasswordDeal .heading-title-t {
//         font-size: 25px;
//         line-height: 70%;
//     }

//     .ThanksPasswordDeal p {
//         font-size: 14px;
//         line-height: 70%;
//     }

//     // .verticall_thanks_center{
//     // padding-top: 120px;
//     // }
//     #create-card.forgot_card_container,
//     #create-card.set_card_container,
//     #create-card.login_card_container {
//         padding-top: 50px;
//         margin-top: 50px;
//         margin-left: 30px;
//         margin-right: 30px;
//         margin-bottom: 50px;
//     }
//     #create-card .text_mng_lr,
//     .text_mng.first_div_top,
//     .text_mng.submit_div {
//         margin-left: 5px;
//         margin-right: 5px;
//     }

//     #create-card .text_mng.submit_div {
//         margin-bottom: 0px;
//     }

//     #create-card.login_card_container.optscreen {
//         margin-left: 10px;
//         margin-right: 10px;
//     }
// }

/*-------------------------------------------------------------------*/

.viewAll {
    cursor: pointer;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    color: #4472C4;
    position: relative;
}

.shopPagination {
    .MuiPagination-root {
        .MuiPagination-ul {
            justify-content: end;
            display: flex;
        }
    }
}

.textarea-input {
    background-color: #FFFFFF;
    cursor: text;
    width: 100%;
    margin-bottom: 0px;
    height: 150px;
    max-height: 11rem;
    -webkit-user-select: text;
    user-select: none;
    padding: 15px;
    border: 1px solid #C0C0C0;
    font-family: "Inter", Arial, sans-serif;
    font-weight: 400;
    line-height: normal;
    color: #808080;
    border-radius: 4px;
}

.input-datepicker {
    border: 1px solid #c0c0c0 !important;

    .MuiFormLabel-root {
        top: -10px;
        left: 15px;
    }

    .MuiInputBase-root {
        margin-top: 0px;
        background: none !important;

        .MuiInputAdornment-root {
            margin-top: 0px !important;
        }
    }
}

.input-datepicker.selectedDate {
    .MuiFormLabel-root {
        display: none;
    }
}

.MuiTabs-root {
    .MuiTabs-fixed {
        .MuiTabs-flexContainer {
            padding-left: 30px;
            border-bottom: 1px solid #C0C0C0;

            .MuiTab-root {
                background: #ECECEC;
                border-width: 1px 0px 0px 1px;
                border-style: solid;
                border-color: #C0C0C0;
                min-height: 48px !important;

                &:last-child {
                    border-width: 1px 1px 0px 1px;
                }

                .MuiTab-wrapper {
                    font-family: "Inter", Arial, sans-serif !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px !important;
                    line-height: 20px;
                    color: #606060;
                    text-transform: capitalize;
                    flex-direction: row-reverse;

                    .MuiSvgIcon-root {
                        margin-top: 5px;
                        margin-left: 5px;
                    }
                }
            }

            .MuiTab-root.Mui-selected {
                background: #FFFFFF;

                .MuiTab-wrapper {
                    font-weight: 600;
                }
            }
        }

        .MuiTabs-indicator {
            background-color: #fff;
            height: 1px;
        }
    }
}

.muiDescripationInfo {
    font-family: "Inter", Arial, sans-serif;
    color: #606060;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
}

.muiDescripationPlanInfo {
    font-family: "Inter", Arial, sans-serif;
    color: #606060;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
}

.muiDescripationInfoAbout {
    font-family: "Inter", Arial, sans-serif;
    color: #919CA4;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
}

.muiDescripationInfoCheckbox {
    font-family: "Inter", Arial, sans-serif;
    color: #808080;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
}

.muiNotificationsIcon {
    background: #233949;
    border-radius: 4px;
    height: 45px;
    width: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    .MuiSvgIcon-root {
        font-size: 24px !important;
    }
}

.colorPickerDiv {
    .colorPickerDivDiv {
        color: #808080;
        white-space: break-spaces;
        padding: 12px 15px;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        color: #808080;
        border: 1px solid #C0C0C0;
        cursor: pointer;
    }
}

.variantSelectedValueText {
    min-width: 10ch;
    min-height: 37px;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.MuiSvgIcon-root {
    font-size: 18px !important;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 16px !important;
}

.listedRadiusImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.listedImg {
    width: 40px;
    height: 40px;
}

.listedImgIcon {
    width: 35px;
    height: 35px;
}

.listedImg100100 {
    width: 100px;
    height: 100px;
}

.myoverlay {
    z-index: 999;
}

@media (max-width: 1400px) {
    .dashboard-date {
        font-size: 12px !important;
    }
}
@media (min-width: 1400px) and (max-width: 1500px) {
    .dashboard-date {
        font-size: 14px !important;
    }
}

@media (min-width: 1500px) and  (max-width: 1600px) {
    .dashboard-date {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 1300px) {
    body {
        font-size: 12px !important;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-size: 12px !important;
    }

    .heading-title {
        font-size: 22px !important;
    }

    .otp-heading {
        font-size: 14px !important;
    }

    .h2-label {
        font-size: 20px !important;
    }

    .h3-label {
        font-size: 18px !important;
    }

    .h4-label {
        font-size: 16px !important;
    }

    .h4-label-heading {
        font-size: 16px !important;
    }

    .h5-label {
        font-size: 14px !important;
    }

    .h5-label-pickup {
        font-size: 14px !important;
    }

    .h5-label-pickup-name {
        font-size: 14px !important;
    }

    .h5-label-annual {
        font-size: 16px;
    }

    .h5-label-heading {
        font-size: 14px !important;
    }

    .h5-label-status {
        font-size: 14px !important;
    }

    .h6-label {
        font-size: 12px !important;
    }

    .h6-label-deliver {
        font-size: 13px !important;
    }

    .h6-label-deliver-check {
        font-size: 14px !important;
    }

    .h6-lable-about-store {
        font-size: 12px !important;
    }

    // .h6-label-tagline{
    //     font-size: 12px !important;
    // }
    .h6-label-heading {
        font-size: 12px !important;
    }

    .muiDescripationInfo {
        font-size: 12px !important;
    }

    .muiDescripationPlanInfo {
        font-size: 13px;
    }

    .muiDescripationInfoCheckbox {
        font-size: 12px !important;
    }

    .MuiFormControl-root {
        height: 40px;

        .MuiInputBase-root {
            height: 40px;

            .MuiSelect-root {
                padding-top: 10px !important;
                font-size: 12px !important;
            }
        }

        .MuiFormLabel-root {
            font-size: 12px !important;
            top: -6px;
        }
    }

    .geosuggest {
        .geosuggest__input-wrapper {
            .geosuggest__input {
                height: 40px;
            }
        }
    }

    .MuiSvgIcon-root {
        font-size: 16px !important;
    }

    .MuiSvgIcon-fontSizeSmall {
        font-size: 14px !important;
    }

    .myoverlay {
        .muiMarginTop-4 {
            margin-top: 1.5rem !important;
        }
    }

    .muiMarginTop-4 {
        margin-top: 1.5rem !important;
    }

    .MuiTabs-root {
        min-height: 40px !important;

        .MuiTabs-fixed {
            .MuiTabs-flexContainer {
                .MuiTab-root {
                    min-height: 40px;

                    .MuiTab-wrapper {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }

    .btn-primary {
        font-size: 12px !important;
        width: 135px;
    }

    .order-btn {
        width: 180px;
    }

    .btn-cancle {
        font-size: 12px !important;
        width: 135px;
    }

    .btn-batch {
        font-size: 12px !important;
        width: 145px;
    }

    .btn-available {
        font-size: 12px !important;
        width: 135px;
    }

    .muiNotificationsIcon {
        height: 40px;
        width: 40px;

        .MuiSvgIcon-root {
            font-size: 22px !important;
        }
    }

    .input-datepicker {
        .MuiFormLabel-root {
            top: -10px;
        }
    }

    .customreact-select {
        height: 40px;

        .css-yk16xz-control {
            height: 40px;

            .css-g1d714-ValueContainer {
                height: 40px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                height: 40px;
            }
        }

        .css-1pahdxg-control {
            height: 40px;

            .css-g1d714-ValueContainer {
                height: 40px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                height: 40px;
            }
        }
    }

    .colorPickerDiv {
        colorPickerDivDiv {
            height: 40px;
        }
    }

    .listedRadiusImg {
        width: 35px;
        height: 35px;
    }

    .listedImg {
        width: 35px;
        height: 35px;
    }

    .listedImg100100 {
        width: 80px;
        height: 80px;
    }

    .screenLeftMargin {
        margin-left: 200px !important;
    }

    .screenLeftMarginEnquiry {
        margin-left: 200px;
    }

    .MuiFormControl-root .MuiInputBase-root .MuiSelect-root {
        padding-top: 0px !important;
        font-size: 12px;
        border-radius: 0px;
        min-height: 2.1876em;
    }

    .MuiFormControl-root .MuiInputBase-root.country_changes {
        padding-top: 8px;
    }
}

@media screen and (max-width: 1100px) {
    .heading-title {
        font-size: 20px !important;
    }

    .otp-heading {
        font-size: 16px !important;
    }

    .h2-label {
        font-size: 18px !important;
    }

    .h3-label {
        font-size: 16px !important;
    }

    .h4-label {
        font-size: 14px !important;
    }

    .h4-label-heading {
        font-size: 14px !important;
    }

    .h5-label {
        font-size: 12px !important;
    }

    .h5-label-pickup {
        font-size: 12px !important;
    }

    .h5-label-pickup-name {
        font-size: 12px !important;
    }

    .h5-label-annual {
        font-size: 14px;
    }

    .h5-label-heading {
        font-size: 14px !important;
    }

    .h5-label-status {
        font-size: 12px !important;
    }

    .MuiFormControl-root {
        height: 35px;

        .MuiInputBase-root {
            height: 35px;

            .MuiSelect-root {
                padding-top: 0px !important;
                font-size: 12px;
                border-radius: 0px;
            }
        }

        .MuiFormLabel-root {
            font-size: 12px;
            top: -8px;
        }
    }

    .geosuggest {
        .geosuggest__input-wrapper {
            .geosuggest__input {
                height: 35px;
            }
        }
    }

    .MuiTabs-root {
        min-height: 35px !important;

        .MuiTabs-fixed {
            .MuiTabs-flexContainer {
                .MuiTab-root {
                    min-height: 35px;
                    min-width: 130px;

                    .MuiTab-wrapper {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .btn-primary {
        font-size: 12px !important;
        width: 130px;
    }

    .order-btn {
        width: 180px;
    }

    .btn-cancle {
        font-size: 12px !important;
        width: 130px;
    }

    .btn-batch {
        font-size: 12px !important;
        width: 145px;
    }

    .btn-available {
        font-size: 12px !important;
        width: 130px;
    }

    .btn-lg {
        width: 150px !important;
    }

    .btn-sm {
        width: 90px !important;
    }

    .muiNotificationsIcon {
        height: 35px;
        width: 35px;

        .MuiSvgIcon-root {
            font-size: 20px !important;
        }
    }

    .input-datepicker {
        .MuiFormLabel-root {
            top: -10px;
        }

        .MuiInputBase-root {
            .MuiInputAdornment-root {
                .MuiButtonBase-root {
                    left: 15px !important;
                }
            }
        }
    }

    .screenLeftMargin {
        margin-left: 200px !important;
    }

    .screenLeftMarginEnquiry {
        margin-left: 200px;
    }

    .customreact-select {
        height: 35px;

        .css-yk16xz-control {
            height: 35px;

            .css-g1d714-ValueContainer {
                height: 35px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                height: 35px;
            }
        }

        .css-1pahdxg-control {
            height: 35px;

            .css-g1d714-ValueContainer {
                height: 35px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                height: 35px;
            }
        }
    }

    .colorPickerDiv {
        height: 35px;

        .colorPickerDivDiv {
            padding: 8px 15px;
        }
    }

    .variantSelectedValueText {
        margin-bottom: 0px;
        min-width: 80px;
        min-height: 35px;
    }

    .image-word {
        width: 80px;
    }

    .storeImageBlock {
        .imgTagBlock {
            width: 80px;
            height: 80px;
        }

        .uploadPhoto {
            width: 80px;
            height: 80px;

            .uploadInfo {
                margin-top: 20px;
            }
        }
    }

    .listedRadiusImg {
        width: 30px;
        height: 30px;
    }

    .listedImg {
        width: 30px;
        height: 30px;
    }

    .MuiSvgIcon-fontSizeSmall {
        font-size: 12px !important;
    }

    .MuiSvgIcon-root {
        font-size: 14px !important;
    }
}

@media screen and (max-width:1050px) {

    .otp_max_width,
    .text_mng_lr.otp_max_width {
        max-width: 400px;
    }
}

/*
@media screen and (max-width: 800px) {
    .mobile-mt-3 {
        margin-top: 1rem !important;
    }
}*/