.packingSlip{
    margin-top: 1vw;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.pick-time{
    width: 155px !important;
    height: 30px;
    padding-left: 13px;
    padding-top: 5px;
    font-weight: bold;
    background-color: #92EC00;
}