@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.cardDesign {
    width: 100%;
    height: 100%;
    --d: 3px;
    --c: blue;
    --f: 24px;
    border: none;
    position: relative;
    display: inline-block;
    margin: 15px 10px 10px 5px;
    background-color: #FAFAFA;
    font-family: "Roboto-Regular";

    .available-color{
        font-size: 16px;
        color: red;
    }

    .cardHeading {
        background-color: #ffffff;
        border: none;

        .plan_heading {
            font-size: 32px !important;
            color: #004E7A;
            margin-top: 80px;
            font-weight: 700;
            font-family: "Roboto-Regular";
        }
        .plan-heading-other{
            font-size: 32px !important;
            color: #004E7A;
            margin-top: 23px;
            font-weight: 700;
            font-family: "Roboto-Regular";
        }

        .cardText {
            font-size: 14px !important;
            font-weight: 400;
            color: #535353;
            font-family: "Roboto-Regular";
        }
    }

    .cardBody {
        background-color: #FAFAFA;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        // margin-top: 5rem;

        .smallHeadings {
            font-size: 16px;
            font-style: normal;
            font-weight:600;
            color: #233949;
        }
    }

    &::before {
        content: attr(data-ribbon);
        position: absolute;
        font-size: 16px !important;
        top: 0px;
        right: 0;
        transform: translate(29.29%, -100%) rotate(45deg);
        color: #233949;
        text-align: center;
        border-bottom: 0;
        transform-origin: bottom left;
        padding: 5px 35px calc(var(--d) + 5px);
        background: #C4C4C4;
        background-clip: padding-box;
        clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - var(--d)) calc(100% - var(--d)), var(--d) calc(100% - var(--d)), 0 100%);
        -webkit-mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
            linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)bottom right;
        -webkit-mask-size: 300vmax 300vmax;
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
    }
}


@media screen and (max-width: 1550px) {
    .cardDesign {
        .cardHeading {
            .plan_heading {
                font-size: 28px !important;
            }
            .plan-heading-other{
                font-size: 28px !important;
            }

            .cardText {
                font-size: 12px !important;
            }
        }

        .cardBody {
            // margin-top: 3rem;

            .btn-cancle {
                font-size: 12px !important;
            }

            .btn-primary {
                font-size: 12px !important;
            }
        }

        &::before {
            font-size: 14px !important;
        }
    }
}

@media screen and (max-width: 1100px) {
    .cardDesign {
        .cardHeading {
            .plan_heading {
                font-size: 26px !important;
            }
            .plan-heading-other{
                font-size: 26px !important;
            }
        }

        .cardBody {
            // margin-top: 2rem;
            padding: 1rem 1.25rem;
        }

        &::before {
            font-size: 12px !important;
        }
    }
}