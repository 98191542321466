.outerDiv {
    display: flex;
}

.loginBusinessDeal {
    height: 100vh;
    width: 50%;
    vertical-align: middle;
    background: hsl(206, 44%, 94%);
    text-align: center;
}

.loginForm {
    background: #FFFFFF;
    text-align: justify;
    align-items: center;
    margin: auto;
}

.shopezImage {
    position: relative;
    top: 22%;
    width: 25vw;
}

.loginPlaceholder {
    position: relative;
    top: 26%;
    font-weight: 400;
    color: #233949;
    opacity: 0.5;
    font-size: '18px';
    font-family: "Segoe UI", Arial, sans-serif;
}

.loginForm .shopezzLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 18px;
}

.back_login {
    padding-top: 5vw;
    padding-right: 5vw;
    cursor: pointer;
}

.back_arrow_login {
    color: #233949;
}

.login_otp_card_container {

    .otp-input {
        input {
            width: 45px !important;
            height: 45px;
            background: #fff !important;
            border: none !important;
            border: 2px solid #909090 !important;
            // border-bottom: 1px solid #ECECEC !important;
            border-radius: 0;
            margin-left: 10px;

            &:focus-visible {
                outline: 0;
            }
        }
    }
}

.heading-reset {
    color: #4472C4;
    text-align: end;
    margin-top: 25px;
    margin-right: 95px;
}

.heading-resend-order {
    margin-top: 25px;
    display: flex;
    justify-content: end;
    padding-right: 0px !important;
    width: 65% !important;
}

.LoginPasswordDeal {
    // height: 61.2rem;
    background-repeat: no-repeat !important;
    background-size: calc(100% - 40px) calc(100% - 56px) !important;
    background-position: 20px 30px !important;
    // background-color: #E8E6FE;
    text-align: center;
    min-height: 100vh;
}

.login_card_container {
    // height: 50rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    padding-top: 100px;
    margin-left: 140px;
    margin-right: 140px;
    // margin-bottom: 170px;
    position: absolute;
    // border: 1px solid #CFCFCF; 
    margin-top: 5%;
    margin-bottom: 5%;
    // padding-top: 100px;
    // padding-bottom: 100px;
}

.text-mng {
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 20px;
}

.text_mng_lr {
    margin-left: 35px;
    margin-right: 35px;
}

.text-mng-submit_div {
    // margin-top: 20px;
    margin-bottom: 41px;
    margin-left: 35px;
    margin-right: 35px;
}

.text_mng.first_div_top {
    margin-top: 45px;
}

.logo_home {
    top: 50px;
    max-width: 200px;
    position: absolute;
    left: 60px;
}

.logo_login {
    position: relative;
    top: 14%;
    max-width: 100%;
    margin-top: -110px;
}

.mobile-setting {
    display: none;
}

.new_img_logo {
    width: 173px;
    height: 173px;
}

.img_div {
    padding-bottom: 20px;
}

@media screen and (max-width:1800px) {
    .heading-reset {
        margin-right: 73px;
    }

    .heading-resend-order{
        width: 70% !important;
    }
}

@media screen and (max-width:1700px) {
    .heading-reset {
        margin-right: 55px;
    }

    // .heading-resend-order{
    //     padding-right: 55px !important;
    // }

    .heading-resend-order{
        width: 73% !important;
    }
}

@media screen and (max-width:1600px) {
    .heading-reset {
        margin-right: 40px;
    }

    // .heading-resend-order{
    //     padding-right: 40px !important;
    // }

    .heading-resend-order{
        width: 80% !important;
    }
}

@media screen and (max-width:1500px) {
    .heading-reset {
        margin-right: 7px;
    }

    // .heading-resend-order{
    //     padding-right: 7px !important;
    // }
    .heading-resend-order{
        width: 82% !important;
    }
}

@media screen and (max-width:1400px) {
    .heading-reset {
        margin-right: 45px;
    }

    // .heading-resend-order{
    //     padding-right: 45px !important;
    // }
    .heading-resend-order{
        width: 88% !important;
    }
}

@media screen and (max-width:1200px) {
    .heading-reset {
        margin-right: 0;
    }

    .heading-resend-order{
        width: 60% !important;
    }
    .text_mng_lr {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width:1100px) {
    .text_mng_lr {
        margin-left: 0;
        margin-right: 0;
    }

    .heading-reset {
        margin-right: 0;
    }

    .heading-resend-order{
        width: 55% !important;
    }
}

@media screen and (max-width: 991px) {
    .heading-reset {
        margin-right: 150px;
    }

    // .heading-resend-order{
    //     padding-right: 150px !important;
    // }
    .h6-label-tagline {
        font-weight: 500;
    }

    .btn-primary-verify {
        width: 390px;
    }
}