.img-center-store{
    display: flex;
    justify-content: center;
    align-items: center;
}

.collectionImage-store {
    width: 50vh;
    height: 36.44vh;
    top: 50.25vh;
    opacity: 0.8;
    margin-top: 6vw;
}
@media screen and (max-width: 1000px) {
    .listSectionAddproduct .row div{
        padding-left: 5px;
        padding-right: 5px;
    }
    .listSectionAddproduct .row div.img_str_sml_set,
    .listSectionAddproduct .row div.min_dtl{
        padding-left: 3px;
        padding-right: 3px;
    }
    .listSectionAddproduct .row div.set_location_sett{
        margin-left: 0px !important;
        padding-left: 3px;
        padding-right: 3px;
    }
}