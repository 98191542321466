
.forgotPasswordDeal {
    // height: 61.2rem;
    background-color: #E8E6FE;
    text-align: center;
    min-height: 100vh;
}
.forgot_card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-top: 100px;
    margin-left: 140px;
    margin-right: 140px;
    position: absolute;
    margin-top: 5%;
    margin-bottom: 5%;
}
// .reset_card_container{
//     margin-top: 10%;
//     margin-bottom: 10%;
// }
// .forgor_css_back{
//     margin-bottom: 77px;
// }
.text_mng{
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 32px;
}
.text_mng.submit_div{
    margin-top: 51px;
    margin-bottom: 52px;
}
.text_mng.first_div_top{
    margin-top: 45px;
}
.logo_home{
    top: 50px;
    max-width: 200px;
    position: absolute;
    left: 60px;
}
.logo_forgot {
    position: relative;
    top: 14%;
    max-width: 100%;
    margin-top: -110px;
}
.forget_left{
    background: #E8E6FE;
}
.mobile-setting{
    display: none;
}
.new_img_logo{
    width: 173px;
    height: 173px; 
}
.img_div{
    padding-bottom: 20px;
}
@media screen and (max-width: 991px){
    .text_mng.first_div_top {
        width: 90%;
    }
    .text_mng .text_mng_lr {
        width: 90%;
    }
}
@media screen and (max-width: 867px){
    .text_mng.first_div_top {
        width: 88%;
    }
    .text_mng .text_mng_lr {
        width: 88%;
    }
}

@media screen and (max-width: 767px){
    .text_mng.first_div_top {
        width: 86%;
    }
    .text_mng .text_mng_lr {
        width: 86%;
    }
}
@media screen and (max-width: 650px) {
    .text_mng.first_div_top {
        width: 83%;
    }
    .text_mng .text_mng_lr {
        width: 85%;
    }
}
@media screen and (max-width: 540px) {
    .text_mng.first_div_top {
        width: 79%;
    }
    .text_mng .text_mng_lr {
        width: 79%;
    }
}
// @media screen and (min-width: 500px){
//     .text_mng.first_div_top {
//         padding-left: 20px;
//     }
// }